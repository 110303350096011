// import $ from 'jquery';
import $ from 'jquery';
import 'slick-carousel';

export default () => {
  $(() => {
    // jQuery Code Here


///////////////////////////////
    // header fix


    $(window).scroll(function () {
      if($(window).scrollTop() > 200) {
        $('header').addClass('fixed');
      } else {
        $('header').removeClass('fixed');
      }
    });

///////////////////////////////
    // slick
    $('#toppage .mv-area ul').slick({
      autoplay: true,
      autoplaySpeed: 5000,
      dots: true,
      arrows: false,
      infinite: true, // 無限スライド
      responsive: [
        {
          breakpoint: 768,
          settings: {
          }
        }
      ]
    });

/////////////活用事例のスリック
  var slideCount = $('#toppage .no2 .inner .thumb-slider-area ul li').length;
    if (slideCount > 4) {
      $('.thumb-slider-area').addClass('slide-all')
    } else {
    if (slideCount <= 4) { 
        $('.thumb-slider-area').addClass('slide-sp')
      }
    } 


//////活用事例4件以下の制御
function checkBreakPoint() {
  var w = $(window).width();
	w = $(window).width();
	if (w <= 768) {
		// スマホ向け（768px以下のとき）
		$('#toppage .no2 .inner .slide-sp ul').not('.slick-initialized').slick({
			//スライドさせる
			slidesToShow: 3,
			slidesToScroll: 1,
			arrows: true,
			autoplay: false,
			autoplaySpeed: 5000,
      responsive: [
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 1, // 表示するスライド枚数
            slidesToScroll: 1,// スライドする数
          }
        }]
		});
	} else {
		// PC向け
		$('#toppage .no2 .slide-sp .slick-initialized').slick('unslick');
	}
}
// ウインドウがリサイズする度にチェック
$(window).resize(function(){
	checkBreakPoint();
});
// 初回チェック
checkBreakPoint();



//////活用事例5件以上の制御
$('#toppage .no2 .inner .slide-all ul').slick({
  autoplay: true, // 自動再生
  autoplaySpeed: 5000,
  slidesToShow: 4, // 表示するスライド枚数
  slidesToScroll: 1,// スライドする数
  infinite: true, // 無限スライド
  arrows: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3, // 表示するスライド枚数
        slidesToScroll: 1,// スライドする数
      }
    }, {

      breakpoint: 640,
      settings: {
        slidesToShow: 1, // 表示するスライド枚数
        slidesToScroll: 1,// スライドする数
      }
    }
  ]
});





/*
  $(function(){
    function sliderSetting(){
        var width = $(window).width();
        if(width <= 768){
            $('#toppage .no2 .inner .slide-sp ul').not('.slick-initialized').slick({
                autoplay: true,
                centerMode: false,
                arrows:true,
                infinite: true,
                dots:true,
                slidesToShow: 1,
                slidesToScroll: 1,
                centerPadding: '0px',
            });
        } else {
            $('#toppage .no2 .inner .slide-sp ul').slick('unslick');
        }
    }
    sliderSetting();
    $(window).resize(function(){
        sliderSetting();
    });
  });
*/

/*
    $('#toppage .no2 .inner .slide-sp ul').slick({
      autoplay: true, // 自動再生
      autoplaySpeed: 5000,
      slidesToShow: 4, // 表示するスライド枚数
      slidesToScroll: 1,// スライドする数
      infinite: true, // 無限スライド
      arrows: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3, // 表示するスライド枚数
            slidesToScroll: 1,// スライドする数
          }
        }, {

          breakpoint: 640,
          settings: {
            slidesToShow: 1, // 表示するスライド枚数
            slidesToScroll: 1,// スライドする数
          }
        }
      ]
    });*/



///////////サービスセンタースリック

    $('.service-slick').slick({
      autoplay: true,
      autoplaySpeed: 5000,
      dots: true,
      arrows: false,
      infinite: true, // 無限スライド
      responsive: [
        {
          breakpoint: 768,
          settings: {
          }
        }
      ]
    });



/////////////switch
    //sp-menu
    var flg = "open"
    $('.menu-button').on('click',function(){
      if(flg == "open"){
        $('body').addClass('on');
        flg = "close"
      }else{
        $('body').removeClass('on');
        flg = "open"
      }
      return false
    });

    //sp-メニュー　アコーディオン
    var flg = "down"
    $('.gnav-switch').on('click',function(){
        $(this).parent().toggleClass('on');
      return false
    });

/*
    $('.parent').hover(
      function() {
        $(this).parent().addClass("on");
      },
      function() {
          //マウスカーソルが離れた時の処理
        $(this).parent().removeClass("on");
      }
    );
*/

    //支援メニュー
    var flg = "off"
    $('.add-switch').on('click',function(){
      if(flg == "off"){
        $(this).parent().addClass('on');
        flg = "on"
      }else{
        $(this).parent().removeClass('on');
        flg = "off"
      }
      return false
    });


    $('.side-navi .add-switch').on('click',function(){
      $(this).parent().removeClass('on');
    });


////////loginチュートリアルの表示
    $('.show-btn').on('click',function(){
      $('.login-form').addClass('on');
    });

    $('.login-form').on('click',function(){
      $(this).removeClass('on');
    });

////////ニュース一覧　ソート機能
    $('.switch-delete').on('click',function(){
      $('#show-area').removeClass();
    });

    $('.switch-service').on('click',function(){
      $('#show-area').removeClass().addClass('on-service');
    });

    $('.switch-event').on('click',function(){
      $('#show-area').removeClass().addClass('on-event');
    });

    $('.switch-info').on('click',function(){
      $('#show-area').removeClass().addClass('on-info');
    });

////////////////////チケットページ

$(document).ready(function(){
  if ( $('body').hasClass("gourmet") )
    $('.ticket-cats1').addClass('on')
  });

$(document).ready(function(){
  if ( $('body').hasClass("concert") )
    $('.ticket-cats2').addClass('on')
  });

$(document).ready(function(){
  if ( $('body').hasClass("art") )
    $('.ticket-cats3').addClass('on')
  });

$(document).ready(function(){
  if ( $('body').hasClass("sports") )
    $('.ticket-cats4').addClass('on')
  });

$(document).ready(function(){
  if ( $('body').hasClass("etc") )
    $('.ticket-cats5').addClass('on')
  });



///////////////////////
  });
}